import React from 'react'
import { Segment, Container, Grid, Header, List, Divider, Image, Icon } from 'semantic-ui-react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Profile from './profile'
import _ from 'lodash'

export default () => (
  <StaticQuery query={graphql`
    query FooterQuery{
    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
        edges {
            node {
                name
                shortBio {
                    shortBio
                }
                title
                facebook
                twitter
                github
                heroImage: image {
                    fluid(
                        maxWidth: 256
                        maxHeight: 256
                        resizingBehavior: PAD
                        background: "rgb:000000"
                    ) {
                        ...GatsbyContentfulFluid_tracedSVG
                    }
                }
            }
        }
        }
      allContentfulBlogPost(filter: {tags: {ne: null}}) {
        edges {
          node {
            tags
          }
        }
      }
    }
`}
               render={({
                          allContentfulPerson: {
                            edges
                          },
                          allContentfulBlogPost
                        }) => (<Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
                   <Container textAlign='center'>
                     <Grid inverted stackable>
                       <Grid.Column width={4}>
                         <Header inverted as='h4' content='Tags'/>
                         <List link inverted>
                           {
                             _.uniq(_.flatMapDeep(allContentfulBlogPost.edges.map((post) => {
                               return post.node.tags
                             })))
                               .map((tag, idx) => (
                                 <List.Item as='span' key={idx}><Link to={`/tags/${tag}`}>{tag}</Link></List.Item>
                               ))
                           }
                         </List>
                       </Grid.Column>
                       <Grid.Column width={6}>
                       </Grid.Column>
                       <Grid.Column width={6}>
                         <Profile data={edges[0].node}/>
                       </Grid.Column>
                     </Grid>
                     <Divider inverted section/>
                     <p>©MERCURY MAEDA</p>
                   </Container>
                 </Segment>
               )}
  />

)
