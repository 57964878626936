import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import defaultImage from '../../static/logo.png'

const SiteMetadata = ({
  contentTitle,
  contentDescription = null,
  pathname,
  imageUrl,
  isIndexPage = false,
}) => (
  <StaticQuery
    query={graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            siteUrl
            title
            description
            twitter
            fbAppId
            locale
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: { siteUrl, title, description, twitter, fbAppId, locale },
      },
    }) => (
      <Helmet
        defaultTitle={title}
        titleTemplate={isIndexPage ? title : `%s | ${title}`}
      >
        <html lang={locale} />
        <title>{contentTitle || title}</title>
        <meta name="description" content={contentDescription || description} />
        <link rel="canonical" href={`${siteUrl}${pathname}`} />
        {/*Facebook*/}
        <meta property="fb:app_id" content={fbAppId} />
        <meta property="og:url" content={`${siteUrl}${pathname}`} />
        <meta property="og:title" content={contentTitle || title} />
        <meta
          property="og:type"
          content={isIndexPage ? 'website' : 'article'}
        />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={title} />
        <meta
          property="og:image"
          content={imageUrl ? imageUrl : `${siteUrl}${defaultImage}`}
        />
        <meta
          property="og:description"
          content={contentDescription || description}
        />
        {/*Twitter*/}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={`@${twitter}`} />
        <meta name="twitter:creator" content={`@${twitter}`} />
        <meta name="twitter:title" content={contentTitle || title} />
        <meta
          name="twitter:description"
          content={contentDescription || description}
        />
        <meta
          name="twitter:image"
          content={imageUrl ? imageUrl : `${siteUrl}${defaultImage}`}
        />
      </Helmet>
    )}
  />
)

SiteMetadata.propTypes = {
  site: PropTypes.object.require,
  contentTitle: PropTypes.string,
  contentDescription: PropTypes.string,
  pathname: PropTypes.string,
  imageUrl: PropTypes.string,
  isIndexPage: PropTypes.bool,
}

export default SiteMetadata
