import React from 'react'
import base from './base.css'
import Header from './header'
import { Responsive, Container, Segment } from 'semantic-ui-react'
import Footer from './footer'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <div>
        <Header/>
        <Container style={{ marginTop: '7em' }}>
          {children}
        </Container>
        <Footer/>
      </div>
    )
  }
}

export default Template

