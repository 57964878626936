import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Button, Icon, Divider, Image, Header } from 'semantic-ui-react'
import SiteMetadata from '../components/site-metadata'
import heroStyles from '../components/hero.module.css'
import PropTypes from 'prop-types'

class BlogPostTemplate extends React.Component {
  _openUrl(url) {
    window.open(url, '_blank')
  }

  _handleShareFacebookClicked() {
    const { origin, pathname } = this.props.location
    const encordedUrl = encodeURIComponent(`${origin}${pathname}`)
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encordedUrl}`
    this._openUrl(url)
  }

  _handleShareTwitterClicked(postTitle) {
    const { origin, pathname } = this.props.location
    const { twitter, title } = this.props.data.site.siteMetadata
    const encordedUrl = encodeURIComponent(`${origin}${pathname}`)
    const url = `https://twitter.com/intent/tweet?url=${encordedUrl}&via=${twitter}&text=${encodeURIComponent(
      `${postTitle} | ${title}`
    )}&related=${twitter}`
    this._openUrl(url)
  }

  _handleShareHatenaClicked() {
    const { host, pathname } = this.props.location
    const url = `http://b.hatena.ne.jp/entry/s/${host}${pathname}`
    this._openUrl(url)
  }

  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <SiteMetadata
            pathname={this.props.location.pathname}
            contentTitle={post.title}
            contentDescription={post.description.description}
            imageUrl={`https:${post.heroImage.fluid.src}`}
          />
          <div className={heroStyles.hero}>
            <Img
              className={heroStyles.heroImage}
              alt={post.title}
              fluid={post.heroImage.fluid}
            />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">{post.title}</h1>
            <p
              style={{
                display: 'block',
              }}
            >
              {post.publishDate}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
            <Divider hidden />
            <Header as="h3">Share</Header>
            <Button
              color="facebook"
              onClick={() => this._handleShareFacebookClicked()}
              icon
            >
              <Icon name="facebook" />
            </Button>
            <Button
              color="twitter"
              onClick={() => this._handleShareTwitterClicked(post.title)}
              icon
            >
              <Icon name="twitter" />
            </Button>
            <Button
              className={'hatena'}
              onClick={() => this._handleShareHatenaClicked()}
              icon
            >
              <Icon>
                <Image src={'/hatenabookmark-logomark.svg'} />
              </Icon>
            </Button>
          </div>
        </div>
      </Layout>
    )
  }
}

BlogPostTemplate.propTypes = {
  location: PropTypes.object.require,
  data: PropTypes.object.require,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      description {
        description
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    site {
      siteMetadata {
        twitter
        title
      }
    }
  }
`
