import React from 'react'
import { Segment, Icon, List, Image, Grid } from 'semantic-ui-react'

export default ({ data }) => (
  <Grid textAlign='left' column={2}>
    <Grid.Row>
      <Grid.Column width={4}>
        <Image src={data.heroImage.fluid.src}
               circular
               alt={`${data.name} icon`}
               size='tiny'/>
      </Grid.Column>
      <Grid.Column width={12}>
        <span>{data.name}</span>
        <p>{data.shortBio.shortBio}</p>
        <List horizontal>
          <List.Item>
            <a href={`https://facebook.com/${data.facebook}`}
               rel="noreferrer" target='_blank'
               title={'facebook link for yohei.maeda.11'}>
              <Icon name='facebook f'/>
            </a>
          </List.Item>
          <List.Item>
            <a href={`https://twitter.com/${data.twitter}`}
               rel="noreferrer"
               target='_blank'
               title={'twitter link for ArubaitoShain'}>
              <Icon name='twitter'/>
            </a>
          </List.Item>
          <List.Item>
            <a href={`https://github.com/${data.github}`}
               rel="noreferrer"
               target='_blank'
               title={'github link for myohei'}>
              <Icon name='github'/>
            </a>
          </List.Item>
          {/*<List.Item>*/}
          {/*<a href={`https://facebook.com/data.facebook/${data.linkedin}`} target='_blank'> <Icon name='linkedin'/></a>*/}
          {/*</List.Item>*/}
        </List>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)