import React from 'react'
import { Menu, Container, Image } from 'semantic-ui-react'
import { Link } from 'gatsby'

export default () => (
  <Menu fixed='top' inverted>
    <Container>
      <Menu.Item as='div' header>
        <Link to={'/'}>
          <Image size='mini'
                 src={'/icon.png'}
                 alt="Logo"
                 style={{ marginRight: '1.5em' }}
                 verticalAlign='middle'/>
          <span>Yohei Blog</span>
        </Link>
      </Menu.Item>
    </Container>
  </Menu>
)